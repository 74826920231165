import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_tabs_container()
})

function dhsv_vc_tabs_container() {
  $('.dhsv_vc_tabs_container').each(function(index, el) {
    let allListElements = $(`#${el.id} li`)
    $(this)
      .find(allListElements)
      .appendTo(`#${el.id} ul.nav`)
  })
}
