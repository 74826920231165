import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_product_tabs()
})

const dhsv_vc_product_tabs = () => {
  $('.js-nav-link').click(function() {
    $(this)
      .closest('.js-row')
      .find('.js-placeholder')
      .addClass('hide')
  })

  $('.js-close').click(function(e) {
    e.stopPropagation()
    const rowNode = $(this).closest('.js-row')
    rowNode.find('.js-placeholder').removeClass('hide')
    rowNode.find('.tab-panel.active.show').removeClass('active show')
    rowNode.find('.js-nav-link.active.show').removeClass('active show')
  })

  $('.js-mobile-tabs-toggle').click(function() {
    $(this)
      .next('.js-main-nav')
      .toggleClass('show')
  })

  $('.js-nav-top').click(function() {
    const tabText = $(this).text()
    const navContainer = $(this).closest('.js-nav-container')
    navContainer.find('.js-main-nav').removeClass('show')
    navContainer.find('.js-current-tab').text(tabText)
  })
}
