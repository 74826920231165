import cssVars from 'css-vars-ponyfill'
import stickElement from '../util/stickit'

const dhsvCommon = {
  searchIsEnabled: false,
  throttled(delay, fn) {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  },
  setBodyWidth() {
    cssVars({
      variables: { '--body-width': document.body.clientWidth + 'px' },
    })
  },
  smoothScroll(link, e) {
    let destination = 0

    if (e) e.preventDefault()
    var hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      destination = $(hash).offset().top - 75
      history.replaceState(null, null, hash)
    }

    $('html, body').animate(
      {
        scrollTop: destination,
      },
      600
    )

    $(window).trigger('hashchange')
  },
  primaryMenuInit() {
    $('body').on('click', '.js-nav-primary-toggler', function() {
      $(this).toggleClass('active')
      $('.js-nav-primary')
        .fadeToggle(300)
        .toggleClass('is-open')
    })
  },
  rapidMailPlaceholder() {
    $('.newsletter #rm-email').attr('placeholder', 'E-Mail-Adresse eingeben')
    $('.newsletter input[type="submit"]').attr('value', '->')
  },
  rapidMailAt() {
    if (
      window.location.href.indexOf('crk002.dev.360vier.net/at/') > -1 ||
      window.location.href.indexOf('carlrieck.de/at/') > -1
    ) {
      $('.newsletter #rm-extra1').val('yes')
    } else {
      $('.newsletter #rm-extra1').val('no')
    }
  },
  showDescriptionBox() {
    // eslint-disable-next-line no-console
    //console.log('showDescriptionBox')
    $('.description_box').each(function() {
      //check for description text
      var description_box_content = $(this).text()

      // eslint-disable-next-line no-console
      //console.log(description_box_content)

      if (description_box_content != '') {
        $(this)
          .parent()
          .addClass('show')
      }
    })

    $('.wpb_text_column.wpb_content_element.show')
      .after()
      .click(function() {
        //alert('clickable!')
        if (
          $(this)
            .find('.description_box')
            .hasClass('show_description_box')
        ) {
          //alert('has class')
          $(this)
            .find('.show_description_box')
            .removeClass('show_description_box')
        } else {
          $(this)
            .find('.description_box')
            .addClass('show_description_box')
        }
      })

    /*
    $('.wpb_text_column.wpb_content_element.show')
      .after()
      .hover(function() {
        //alert('clickable!')
        if (
          $(this)
            .find('.description_box')
            .hasClass('show_description_box')
        ) {
          //alert('has class')
          $(this)
            .find('.show_description_box')
            .removeClass('show_description_box')
        } else {
          $(this)
            .find('.description_box')
            .addClass('show_description_box')
        }
      })
      */
  },

  showFormDescriptionBox() {
    // eslint-disable-next-line no-console
    //console.log('showFormDescriptionBox')

    $('.gform_wrapper form .gform_body ul.gform_fields li').each(function() {
      // eslint-disable-next-line no-console
      //console.log('Form List Element')

      //check for description text
      var form_description_box_content = $(this)
        .find('.gfield_description')
        .text()

      // eslint-disable-next-line no-console
      //console.log(form_description_box_content)

      $('.ginput_container_textarea', this)
        .parent()
        .addClass('has_textarea')

      if (form_description_box_content != '') {
        $(this).addClass('show')
      }
    })

    $('.gform_wrapper form .gform_body ul.gform_fields li.show')
      .after()
      .click(function() {
        //alert('clickable!')
        if (
          $(this)
            .find('.gfield_description')
            .hasClass('show_description_box')
        ) {
          //alert('has class')
          $(this)
            .find('.gfield_description')
            .removeClass('show_description_box')
        } else {
          $(this)
            .find('.gfield_description')
            .addClass('show_description_box')
        }
      })
  },

  init() {
    this.primaryMenuInit()
    this.setBodyWidth()
    this.rapidMailPlaceholder()
    this.rapidMailAt()
    this.showDescriptionBox()
    this.showFormDescriptionBox()

    if (window.location.hash) {
      this.smoothScroll(window.location.hash)
    }

    $('.js-search-toggle').on('click', e => {
      e.preventDefault()
      this.toggleSearch()
    })

    $('.js-close-infobar').on('click', e => {
      e.preventDefault()
      this.toggleInfobar()
    })
  },
  onResize() {
    $(window).on('resize', () => {
      this.setBodyWidth()
    })
  },
  megaMenuListeners() {
    $(window).load(() => {
      $('li.mega-menu-item').on('open_panel', () => {
        triggerActiveState()
        if (this.searchIsEnabled) {
          this.toggleSearch()
        }
      })
      $('li.mega-menu-item').on('close_panel', triggerActiveState)
    })

    const triggerActiveState = () => {
      $('.banner').toggleClass('is-active')
    }
  },
  toggleSearch() {
    this.searchIsEnabled = !$('.js-search-toggle').hasClass('is-opened')
    $('.js-search-toggle')[(this.searchIsEnabled ? 'add' : 'remove') + 'Class'](
      'is-opened'
    )
    $('.js-searchform')['slide' + (this.searchIsEnabled ? 'Down' : 'Up')]()
  },
  toggleInfobar() {
    $('.js-infobar').slideUp()
    document.cookie = 'infobar_rejected=true'
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.init()
    dhsvCommon.onResize()

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      function(e) {
        dhsvCommon.smoothScroll($(this).attr('href'), e)
      }
    )
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    stickElement('header.banner', {
      holderClass: 'banner__holder',
    })
    dhsvCommon.megaMenuListeners()
  },
}
